import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  UUID: any;
};

export type DebugSchema = {
  __typename?: 'DebugSchema';
  _debug?: Maybe<DjangoDebug>;
  listTasks?: Maybe<Array<Maybe<TaskType>>>;
  me?: Maybe<UserType>;
};


export type DebugSchemaListTasksArgs = {
  day?: InputMaybe<Scalars['DateTime']>;
};

/** Debugging information for the current query. */
export type DjangoDebug = {
  __typename?: 'DjangoDebug';
  /** Raise exceptions for this API query. */
  exceptions?: Maybe<Array<Maybe<DjangoDebugException>>>;
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<DjangoDebugSql>>>;
};

/** Represents a single exception raised. */
export type DjangoDebugException = {
  __typename?: 'DjangoDebugException';
  /** The class of the exception */
  excType: Scalars['String'];
  /** The message of the exception */
  message: Scalars['String'];
  /** The stack trace */
  stack: Scalars['String'];
};

/** Represents a single database query made to a Django managed DB. */
export type DjangoDebugSql = {
  __typename?: 'DjangoDebugSQL';
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
};

export type ErrorType = {
  __typename?: 'ErrorType';
  message?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  saveTask?: Maybe<SaveTask>;
  toggleCompleteTask?: Maybe<ToggleCompleteTask>;
};


export type MutationSaveTaskArgs = {
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['UUID']>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
};


export type MutationToggleCompleteTaskArgs = {
  id: Scalars['UUID'];
};

export type SaveTask = {
  __typename?: 'SaveTask';
  error?: Maybe<ErrorType>;
  task?: Maybe<TaskType>;
};

export type TaskType = {
  __typename?: 'TaskType';
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  id: Scalars['UUID'];
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ToggleCompleteTask = {
  __typename?: 'ToggleCompleteTask';
  error?: Maybe<ErrorType>;
  task?: Maybe<TaskType>;
};

export type UserType = {
  __typename?: 'UserType';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type ListTasksQueryVariables = Exact<{
  day?: InputMaybe<Scalars['DateTime']>;
}>;


export type ListTasksQuery = { __typename?: 'DebugSchema', listTasks?: Array<{ __typename?: 'TaskType', id: any, title: string, description: string, completed: boolean, startDate: any, endDate: any } | null> | null };

export type SaveTaskMutationVariables = Exact<{
  id?: InputMaybe<Scalars['UUID']>;
  title: Scalars['String'];
  description: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type SaveTaskMutation = { __typename?: 'Mutation', saveTask?: { __typename?: 'SaveTask', task?: { __typename?: 'TaskType', id: any, title: string, description: string, startDate: any, endDate: any } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };

export type ToggleCompleteTaskMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ToggleCompleteTaskMutation = { __typename?: 'Mutation', toggleCompleteTask?: { __typename?: 'ToggleCompleteTask', task?: { __typename?: 'TaskType', id: any, completed: boolean } | null, error?: { __typename?: 'ErrorType', message?: string | null } | null } | null };


export const ListTasksDocument = gql`
    query ListTasks($day: DateTime) {
  listTasks(day: $day) {
    id
    title
    description
    completed
    startDate
    endDate
  }
}
    `;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      day: // value for 'day'
 *   },
 * });
 */
export function useListTasksQuery(baseOptions?: Apollo.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
      }
export function useListTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTasksQuery, ListTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
        }
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<typeof useListTasksLazyQuery>;
export type ListTasksQueryResult = Apollo.QueryResult<ListTasksQuery, ListTasksQueryVariables>;
export const SaveTaskDocument = gql`
    mutation SaveTask($id: UUID, $title: String!, $description: String!, $startDate: DateTime!, $endDate: DateTime!) {
  saveTask(
    id: $id
    title: $title
    description: $description
    startDate: $startDate
    endDate: $endDate
  ) {
    task {
      id
      title
      description
      startDate
      endDate
    }
    error {
      message
    }
  }
}
    `;
export type SaveTaskMutationFn = Apollo.MutationFunction<SaveTaskMutation, SaveTaskMutationVariables>;

/**
 * __useSaveTaskMutation__
 *
 * To run a mutation, you first call `useSaveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskMutation, { data, loading, error }] = useSaveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useSaveTaskMutation(baseOptions?: Apollo.MutationHookOptions<SaveTaskMutation, SaveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTaskMutation, SaveTaskMutationVariables>(SaveTaskDocument, options);
      }
export type SaveTaskMutationHookResult = ReturnType<typeof useSaveTaskMutation>;
export type SaveTaskMutationResult = Apollo.MutationResult<SaveTaskMutation>;
export type SaveTaskMutationOptions = Apollo.BaseMutationOptions<SaveTaskMutation, SaveTaskMutationVariables>;
export const ToggleCompleteTaskDocument = gql`
    mutation ToggleCompleteTask($id: UUID!) {
  toggleCompleteTask(id: $id) {
    task {
      id
      completed
    }
    error {
      message
    }
  }
}
    `;
export type ToggleCompleteTaskMutationFn = Apollo.MutationFunction<ToggleCompleteTaskMutation, ToggleCompleteTaskMutationVariables>;

/**
 * __useToggleCompleteTaskMutation__
 *
 * To run a mutation, you first call `useToggleCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleCompleteTaskMutation, { data, loading, error }] = useToggleCompleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleCompleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<ToggleCompleteTaskMutation, ToggleCompleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleCompleteTaskMutation, ToggleCompleteTaskMutationVariables>(ToggleCompleteTaskDocument, options);
      }
export type ToggleCompleteTaskMutationHookResult = ReturnType<typeof useToggleCompleteTaskMutation>;
export type ToggleCompleteTaskMutationResult = Apollo.MutationResult<ToggleCompleteTaskMutation>;
export type ToggleCompleteTaskMutationOptions = Apollo.BaseMutationOptions<ToggleCompleteTaskMutation, ToggleCompleteTaskMutationVariables>;