import React, { useContext } from "react";
import {
  Card,
  Stack,
  Typography,
  IconButton,
  TextField,
  Tooltip,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { colors } from "../theme";
import modalContext from "../contexts/modalContext";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { DateTime } from "luxon";
import { ReactComponent as ButtonBg } from "../assets/button-bg-medium.svg";
import { useSaveTaskMutation } from "../graphql-schema";

type Props = {
  initialValues: {
    id?: string;
    title?: string;
    description?: string;
    startDate: DateTime;
    endDate: DateTime;
  };
};

const SaveTask = (props: Props) => {
  const { setModal } = useContext(modalContext);
  const [saveTask] = useSaveTaskMutation({
    refetchQueries: ["ListTasks"],
  });
  const [saving, setSaving] = React.useState(false);
  const handleClose = () => {
    setModal(null);
  };
  const [startDate, setStartDate] = React.useState<DateTime | null>(
    props.initialValues?.startDate
  );
  const [endDate, setEndDate] = React.useState<DateTime | null>(
    props.initialValues?.endDate
  );
  const [title, setTitle] = React.useState(props.initialValues?.title || "");
  const [description, setDescription] = React.useState("");
  const [errorText, setErrorText] = React.useState("");

  const handleSubmit = async () => {
    setSaving(true);

    const response = await saveTask({
      variables: {
        id: props.initialValues?.id,
        title,
        description,
        startDate: startDate?.toISO(),
        endDate: endDate?.toISO(),
      },
    });
    if (response?.data?.saveTask?.task?.id) {
      handleClose();
    } else {
      setErrorText(
        response?.data?.saveTask?.error?.message || "Something went wrong"
      );
    }
    setSaving(false);
  };

  return (
    <Card
      sx={{
        backgroundColor: "white",
        width: "30%",
        maxHeight: "50%",
        padding: 6,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        <Stack direction="row" alignItems="center" padding={1.5}>
          <PlaylistAddIcon sx={{ color: colors.mainYellow, fontSize: 24 }} />
          <Typography variant="h6" ml={2}>
            {`Find something to do`}
          </Typography>
        </Stack>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Stack width="80%" alignItems={"flex-start"}>
        <TextField
          variant="standard"
          label="Title"
          sx={{
            marginBottom: 2,
            paddingBottom: 0,
            "& label": {
              top: 7,
            },
          }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
        <TextField
          fullWidth
          variant="standard"
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{
            marginBottom: 2,
            paddingBottom: 0,
            "& label": {
              top: 7,
            },
          }}
        />

        <DesktopDateTimePicker
          label={"Start"}
          inputFormat="d/M/yy, T"
          value={startDate}
          onChange={(val) => {
            setStartDate(val);
          }}
          ampm={false}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ marginTop: 4, marginBottom: 2 }}
              className="outlined-input-form outlined-date-input-form"
            />
          )}
        />
        <DesktopDateTimePicker
          label={"End"}
          inputFormat="d/M/yy, T"
          value={endDate}
          onChange={(val) => {
            setEndDate(val);
          }}
          ampm={false}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ marginTop: 4, marginBottom: 4 }}
              className="outlined-input-form outlined-date-input-form"
            />
          )}
        />

        <Box sx={{ height: 30 }}>
          {errorText && (
            <Typography variant="body2" color="error">
              {errorText}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              fontWeight: 900,
              color: "white",
              backgroundColor: "white",
              boxShadow: "none",
              position: "relative",
              zIndex: 1,
              "&:hover": {
                backgroundColor: "white",
                boxShadow: "none",
              },
              "&:hover svg": {
                fill: colors.darkYellow,
                stroke: colors.darkYellow,
              },
            }}
          >
            <ButtonBg
              style={{
                position: "absolute",
                zIndex: -1,
              }}
              fill={colors.mainYellow}
              stroke={colors.mainYellow}
            />
            Sav{saving ? "ing" : "e"} task
          </Button>
        </Box>
      </Stack>
    </Card>
  );
};

export default SaveTask;
