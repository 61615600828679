import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as SidebarBackground } from "./assets/sidebar-item.svg";
import { colors } from "./theme";

type Props = {
  label: string;
  value: string;
  selected?: boolean;
  setSelectedTab: () => void;
};

const SidebarItem = (props: Props) => {
  return (
    <Box
      position={"relative"}
      onClick={props.setSelectedTab}
      className="sidebar-item"
      sx={{
        marginBottom: 8,
        "&:hover": {
          cursor: "pointer",
          "& > svg": {
            stroke: colors.lightYellow,
            fill: colors.lightYellow,
          },
        },
      }}
    >
      <SidebarBackground
        style={{ position: "absolute" }}
        stroke={props.selected ? colors.lightYellow : colors.veryLightYellow}
        fill={props.selected ? colors.lightYellow : colors.veryLightYellow}
        className="sidebar-background"
      />
      <Typography
        variant="body1"
        sx={{
          zIndex: 1,
          position: "relative",
          marginLeft: 5,
          top: 12,
          fontWeight: "bold",
          color: props.selected ? colors.almostBlack : colors.grey,
        }}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export default SidebarItem;
