import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {}
}

export const colors: { [name: string]: string } = {
  white: "#FFFFFF",
  bgWhite: "#F5F5F5",
  veryLightGrey: "#E4E4E2", // borders
  lightGrey: "#C7C8C3", // main
  grey: "#81837c", // font
  darkGrey: "#4B4B4E",
  almostBlack: "#252527",
  black: "#000000",
  veryLightYellow: "#FEF0D3",
  lightYellow: "#FEE09E",
  mainYellow: "#FFAE00",
  darkYellow: "#CC8B00",
  mainPink: "#F39DBA",
  lightPink: "#F9D2DF",
  brightRed: "#E74430",
  green: "#266B47",
  orange: "#F1793E",
};

const tempTheme = createTheme({
  typography: {
    fontFamily: ["Signika Negative", "-apple-system"].join(","),
    fontWeightBold: 700,
    fontWeightMedium: 300,
    h5: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    body1: {
      fontSize: "1rem",
      color: colors.almostBlack,
      fontWeight: 300,
    },
    body2: {
      fontSize: "0.8rem",
      color: colors.almostBlack,
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: "1rem",
      color: colors.grey,
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: "0.8rem",
      color: colors.grey,
      fontWeight: 300,
    },
  },
  spacing: 4,
  palette: {
    primary: {
      light: colors.lightYellow,
      main: colors.mainYellow,
      dark: colors.darkYellow,
    },
    secondary: {
      light: colors.lightPink,
      main: colors.mainPink,
    },
    error: {
      main: colors.brightRed,
    },
    warning: {
      main: colors.orange,
    },
    success: {
      main: colors.green,
    },
    text: {
      primary: colors.almostBlack,
      secondary: colors.grey,
      disabled: colors.lightGrey,
    },
    info: {
      main: colors.grey,
    },
  },
});

export const theme = createTheme(tempTheme, {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "8px 14px !important",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          minWidth: "120px",
          '&.select-form .MuiInputLabel-root[data-shrink="false"], \
          &.outlined-input-form .MuiInputLabel-root[data-shrink="false"], \
          &.outlined-date-input-form .MuiInputLabel-root[data-shrink="false"]':
            {
              top: "-7px",
            },
          "&.outlined-date-input-form input": {
            width: 130,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
