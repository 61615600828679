import React from "react";

const useLogin = () => {
  const loginUser = async (email: string, password: string) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOSTNAME}/auth/jwt/create/`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
        credentials: "same-origin",
      }
    );
    return response;
  };

  return {
    loginUser,
  };
};

export default useLogin;
