import { Box, Typography, Card } from "@mui/material";
import React from "react";
import { colors } from "./theme";
import { DateTime } from "luxon";
import { colorWithOpacity } from "./utils";

type Props = {
  date?: DateTime;
  createTask: () => void;
};

const HalfHour = (props: Props) => {
  const createTask = () => {
    props.createTask();
  };
  return (
    <Box
      sx={{
        height: 50,
        width: "30%",
        margin: "auto",
        position: "relative",

        "&:hover .placeholder-task": {
          display: "block",
        },
      }}
    >
      <Card
        className="placeholder-task"
        sx={{
          position: "absolute",
          top: 0,
          left: 20,
          width: 260,
          height: "50px",
          zIndex: 2,
          paddingX: 3,
          paddingY: 1,
          cursor: "pointer",
          display: "none",
        }}
        onClick={createTask}
      >
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "500", opacity: 0.5 }}>
            New thing
          </Typography>
          <Typography variant="subtitle2"> </Typography>
        </Box>
      </Card>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: -32,
          width: 64,
          height: "1px",
          bgcolor: colors.veryLightGrey,
        }}
      ></Box>
      {props.date?.toFormat("HH:mm").split(":")[1] === "00" && (
        <Box
          sx={{
            position: "absolute",
            top: -10,
            left: -24,
            width: 48,
            paddingX: 2,
            bgcolor: colors.white,
            boxShadow: `inset 0 0 0 1000px ${colorWithOpacity(
              colors.lightYellow,
              0.2
            )}`,
          }}
        >
          <Typography variant="subtitle2">
            {props.date?.toFormat("HH:mm")}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 300 - 32,
          width: 64,
          height: "1px",
          bgcolor: colors.veryLightGrey,
        }}
      ></Box>
    </Box>
  );
};

export default HalfHour;
