import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Dashboard from "./Dashboard";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { theme } from "./theme";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  GlobalStyles,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import Login from "./Login";
import { ModalProvider } from "./contexts/modalContext";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import userContext, { UserProvider } from "./contexts/userContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AuthenticatedApolloProvider = ({ children }: any) => {
  const { authToken } = React.useContext(userContext);
  const client = React.useMemo(
    () =>
      new ApolloClient({
        uri: `${process.env.REACT_APP_API_HOSTNAME}/api/graphql`,
        cache: new InMemoryCache({}),
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    [authToken]
  );
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = React.useContext(userContext);

  return !isLoggedIn ? <Navigate to="/login" replace /> : children;
};

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <CssBaseline />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <AuthenticatedApolloProvider>
                        <ModalProvider>
                          <Dashboard />
                        </ModalProvider>
                      </AuthenticatedApolloProvider>
                    </RequireAuth>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
