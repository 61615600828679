import React, { useContext, Suspense } from "react";
import { Box, Container } from "@mui/material";
import { colorWithOpacity } from "./utils";
import { colors } from "./theme";
import { ReactComponent as Logo } from "./assets/logo.svg";
import SidebarItem from "./SidebarItem";
import DaysList from "./DaysList";
import modalContext from "./contexts/modalContext";
import Modal from "./Modal";
import SaveTask from "./tasks/SaveTask";
import { ReactComponent as Sand } from "./assets/sand.svg";
import { ReactComponent as BeachUmbrella } from "./assets/beach-umbrella.svg";

function Dashboard() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { modal, setModal } = useContext(modalContext);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        bgColor: "white",
        boxShadow: `inset 0 0 0 1000px ${colorWithOpacity(
          colors.lightYellow,
          0.2
        )}`,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Sand
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 2,
        }}
      />
      <BeachUmbrella
        style={{
          position: "absolute",
          bottom: 4,
          right: 32,
        }}
      />
      <Modal>
        {modal?.type === "save-task" ? (
          <Suspense fallback={<div />}>
            <SaveTask {...modal?.info} />
          </Suspense>
        ) : (
          <div />
        )}
      </Modal>
      <Box
        sx={{
          width: 280,
          height: "100vh",
          overflow: "hidden",
          backgroundColor: colors.veryLightYellow,
          paddingRight: 8,
          zIndex: 10,
        }}
      >
        <Logo style={{ height: 100, width: 100, margin: 8, marginLeft: 16 }} />
        <Box
          sx={{
            marginTop: 8,
            marginLeft: 8,
          }}
        >
          <SidebarItem
            label="Days"
            value="days"
            selected={selectedTab === 0}
            setSelectedTab={() => setSelectedTab(0)}
          />
          <SidebarItem
            label="Weeks"
            value="weeks"
            selected={selectedTab === 1}
            setSelectedTab={() => setSelectedTab(1)}
          />
          <SidebarItem
            label="Months"
            value="months"
            selected={selectedTab === 2}
            setSelectedTab={() => setSelectedTab(2)}
          />
        </Box>
      </Box>
      <Container
        sx={{
          flex: 1,
          overflow: "hidden",
          mr: 75,
        }}
      >
        <DaysList />
      </Container>
    </Box>
  );
}

export default Dashboard;
