import React, { useContext } from "react";
import { Box, Typography, Card, TextField, Button } from "@mui/material";
import { colorWithOpacity } from "./utils";
import { colors } from "./theme";
import { ReactComponent as ButtonBg } from "./assets/button-bg-medium.svg";
import useLogin from "./hooks/useLogin";
import userContext from "./contexts/userContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const { setAuthToken } = useContext(userContext);
  const navigate = useNavigate();

  const { loginUser } = useLogin();

  const handleSubmit = async () => {
    try {
      if (!email || !password) {
        setError("Please enter an email and password");
        return;
      }
      const response = await loginUser(email, password);
      const jsonResponse = await response.json();
      const token = jsonResponse.access;
      if (token) {
        setAuthToken(token);
        navigate("/");
      }
    } catch (e) {
      setError(`${e}`);
    }
  };
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        bgColor: "white",
        boxShadow: `inset 0 0 0 1000px ${colorWithOpacity(
          colors.lightYellow,
          0.2
        )}`,
        overflow: "hidden",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          padding: 4,
          width: "30%",
          maxHeight: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" sx={{ marginTop: 8 }}>
          Log in
        </Typography>
        <Typography variant="subtitle1">Sunshine awaits</Typography>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="standard"
          placeholder="Email"
          sx={{ marginTop: 8 }}
        />

        <TextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="standard"
          placeholder="Password"
          sx={{ marginTop: 2 }}
          type="password"
        />

        {error && (
          <Typography
            variant="subtitle1"
            sx={{
              color: colors.brightRed,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            {error}
          </Typography>
        )}

        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{
            fontWeight: 900,
            color: "white",
            backgroundColor: "white",
            boxShadow: "none",
            position: "relative",
            zIndex: 1,
            marginTop: 8,
            marginBottom: 8,
            "&:hover": {
              backgroundColor: "white",
              boxShadow: "none",
            },
            "&:hover svg": {
              fill: colors.darkYellow,
              stroke: colors.darkYellow,
            },
          }}
        >
          <ButtonBg
            style={{
              position: "absolute",
              zIndex: -1,
            }}
            fill={colors.mainYellow}
            stroke={colors.mainYellow}
          />
          Submit
        </Button>
      </Card>
    </Box>
  );
};

export default Login;
