import React from "react";
import { Modal } from "../types";

// The type of the context
export type ModalContext = {
  modal: Modal | null;
  setModal: React.Dispatch<React.SetStateAction<Modal | null>>;
};

// The exported global value with a default value
const modalContext = React.createContext<ModalContext>({
  modal: null,
  setModal: () => {},
});

// The wrapper of the component that uses the global value
export const ModalProvider = (props: { children: React.ReactNode }) => {
  const [modal, setModal] = React.useState<Modal | null>(null);
  return (
    <modalContext.Provider value={{ modal, setModal }}>
      {props.children}
    </modalContext.Provider>
  );
};

export default modalContext;
