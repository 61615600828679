import { Card, Box, Typography, IconButton } from "@mui/material";
import { DateTime } from "luxon";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { useToggleCompleteTaskMutation } from "../graphql-schema";
import React from "react";
import modalContext from "../contexts/modalContext";

type Props = {
  id: string;
  title: string;
  subtitle?: string;
  startDate: DateTime;
  endDate: DateTime;
  completed?: boolean;
};

const Task = (props: Props) => {
  const { setModal } = React.useContext(modalContext);
  const startHour = props.startDate?.toFormat("HH:mm").split(":")[0];
  const startMinute = props.startDate?.toFormat("HH:mm").split(":")[1];
  const endHour = props.endDate?.toFormat("HH:mm").split(":")[0];
  const endMinute = props.endDate?.toFormat("HH:mm").split(":")[1];
  const [toggleComplete] = useToggleCompleteTaskMutation();

  const startOffset =
    ((parseInt(startHour) * 60 + parseInt(startMinute)) * 100) / 60 + 32;
  const height =
    ((parseInt(endHour) * 60 + parseInt(endMinute)) * 100) / 60 -
    startOffset +
    32;

  const handleClick = () => {
    setModal({
      type: "save-task",
      info: {
        initialValues: {
          id: props.id,
          title: props.title,
          description: props.subtitle,
          startDate: props.startDate,
          endDate: props.endDate,
          completed: props.completed,
        },
      },
    });
  };

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    const response = toggleComplete({
      variables: {
        id: props.id,
      },
      refetchQueries: ["ListTasks"],
    });
  };

  return (
    <Card
      sx={{
        position: "absolute",
        top: startOffset,
        left: 330,
        width: 260,
        height: height,
        zIndex: 1,
        paddingX: 3,
        paddingY: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <Box>
        <Typography variant="body1" sx={{ fontWeight: "500" }}>
          {props.title}
        </Typography>
        <Typography variant="subtitle2">{props.subtitle}</Typography>
      </Box>
      <Box>
        <IconButton size="small" onClick={handleToggle}>
          {props.completed ? (
            <CheckBoxOutlinedIcon sx={{ color: "green" }} fontSize="small" />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Card>
  );
};

export default Task;
