import React from "react";

interface UserContext {
  authToken: null | string;
  setAuthToken: (token: string | null) => void;
  isLoggedIn: boolean;
  signOut: () => void;
}

const AUTH_STORAGE_KEY = "__A__";

const userContext = React.createContext<UserContext>({
  authToken: null,
  setAuthToken: () => {},
  isLoggedIn: false,
  signOut: () => {},
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const currentAuth = localStorage.getItem(AUTH_STORAGE_KEY);
  const [authToken, setAuthToken] = React.useState(currentAuth);

  const setAuthTokenFunc = React.useCallback(
    (token: string | null) => {
      localStorage.setItem(AUTH_STORAGE_KEY, token ?? "");
      setAuthToken(token);
    },
    [setAuthToken]
  );

  const isLoggedIn = React.useMemo(() => !!authToken, [authToken]);

  const signOut = React.useCallback(() => {
    setAuthTokenFunc(null);
  }, [setAuthTokenFunc]);

  const fetchUserDetails = React.useCallback(async () => {
    if (!authToken) {
      return;
    }
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_API_ROOT}/auth/users/me/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
          },
          mode: "cors",
          credentials: "same-origin",
        }
      );
      if (resp.status === 401) {
        setAuthTokenFunc(null);
      }
      if (resp.status !== 200) {
        throw Error("Invalid request");
      }
    } catch (e) {}
  }, [authToken, setAuthTokenFunc]);

  React.useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  return (
    <userContext.Provider
      value={{
        authToken,
        isLoggedIn,
        setAuthToken: setAuthTokenFunc,
        signOut: signOut,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export default userContext;
