import React, { useContext } from "react";
import modalContext from "./contexts/modalContext";
import { Modal as MuiModal } from "@mui/material";

type Props = {
  children: JSX.Element;
};

const Modal = (props: Props) => {
  const { modal, setModal } = useContext(modalContext);
  return (
    <MuiModal
      open={!!modal}
      onClose={() => setModal(null)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: modal?.zIndex,
      }}
    >
      {props.children}
    </MuiModal>
  );
};

export default Modal;
