import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { colors } from "./theme";
import { Info, DateTime } from "luxon";
import HalfHour from "./HalfHour";
import Task from "./tasks/Task";
import modalContext from "./contexts/modalContext";
import { useListTasksQuery } from "./graphql-schema";

type ClickLocation = {};

const DaysList = () => {
  const [selectedDay, setSelectedDay] = React.useState(1);
  const { setModal, modal } = useContext(modalContext);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const [clickingStart, setClickingStart] =
    React.useState<ClickLocation | null>(null);
  const { data } = useListTasksQuery({
    variables: {
      day: DateTime.local().startOf("day").toISO(),
    },
  });

  const createTask = (date: DateTime) => {
    setModal({
      type: "save-task",
      info: {
        initialValues: {
          startDate: date,
          endDate: date.plus({ minutes: 30 }),
        },
      },
    });
  };

  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 50 * 16;
    }
  }, [selectedDay]);

  return (
    <Box
      display="flex"
      ml={48}
      sx={{
        overflow: "hidden",
        height: "100%",
        flexDirection: "column",
      }}
      // onMouseDown={() => setClicking(true)}
      // onMouseUp={() => setClicking(false)}
    >
      <Box display="flex" pb={1}>
        <Box
          sx={{
            width: 300,
            height: "100%",
            mt: 15,
            overflow: "hidden",
          }}
        >
          <Typography variant="h5" color={colors.grey} textAlign="center">
            {Info.weekdays("long")[0]}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 300,
            height: "100%",
            mt: 15,
            overflow: "hidden",
          }}
        >
          <Typography variant="h5" textAlign="center">
            {Info.weekdays("long")[1]}
          </Typography>
        </Box>
        <Box
          sx={{
            width: 300,
            height: "100%",
            mt: 15,
            overflow: "hidden",
          }}
        >
          <Typography variant="h5" color={colors.grey} textAlign="center">
            {Info.weekdays("long")[2]}
          </Typography>
        </Box>
      </Box>
      <Box
        className="entries-container"
        sx={{
          paddingTop: 8,
          borderTop: `1px solid ${colors.veryLightGrey}`,
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          position: "relative",
        }}
        ref={scrollRef}
      >
        {data?.listTasks?.map((t) =>
          !!t ? (
            <Task
              {...t}
              startDate={DateTime.fromISO(t?.startDate)}
              endDate={DateTime.fromISO(t?.endDate)}
            />
          ) : null
        )}
        {Array(48)
          .fill(0)
          .map((_, idx) => {
            const date = DateTime.local().set({
              hour: Math.floor(idx / 2),
              minute: (idx % 2) * 30,
            });

            return <HalfHour date={date} createTask={() => createTask(date)} />;
          })}
      </Box>
    </Box>
  );
};

export default DaysList;
